import { Partition } from '@snapchat/graphene';

import type { SiteConfiguration } from '../../configTypes';
import { LoggingVendor } from '../../helpers/logging/eventListenerTypes';

export const config: SiteConfiguration = {
  domainName: 'educators.snapchat.com',
  globalNavProps: {
    defaultGroupKey: 'safety',
  },
  trackingSettings: {
    eventListeners: [
      { vendor: LoggingVendor.BLIZZARD },
      { vendor: LoggingVendor.CONSOLE },
      { vendor: LoggingVendor.GOOGLE_CLOUD_LOGGING },
      { vendor: LoggingVendor.GOOGLE_CLOUD_MONITORING },
      { vendor: LoggingVendor.GOOGLE_CLOUD_ERROR_REPORTING },
      { vendor: LoggingVendor.MWP_HERMES },
      { vendor: LoggingVendor.GRAPHENE, partitionName: Partition.WEB_COMMON },
      {
        vendor: LoggingVendor.GOOGLE_TAG_MANAGER,
        googleTagManagerId: 'GTM-PB72WQ3',
      },
      {
        vendor: LoggingVendor.SENTRY,
        projectName: 'educators-snapchat-com',
        dsn: 'https://c1198e9be5016e2fa1b00a66685ae9fc@sentry.sc-prod.net/237',
      },
    ],
  },
  localeFilter: ['en-US'],
};
